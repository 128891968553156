import React from "react";
import {
  SEO,
  PageLayout,
  DynamicTiles,
  MultipleComponentIterator,
  Section,
  MarkdownContent,
  Accordion,
  MultipleComponentIteratorMap,
  InternalLink,
  BasicCarousel,
  componentIterator,
  Image,
  FishermanIcon,
  FadeReveal,
  OutboundLink,
  SlideReveal,
  BackgroundImageTeamMember,
  PaginatedGrid,
  SocialMediaV2,
} from "@bluefin/components";
import { Header, Grid, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withArgs,
  withNamedArgs,
  getBusinessFiles,
} from "../utils/utils";
import {
  createServiceItemSlug,
  createServiceCategorySlug,
  createTeamMemberSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      dynamicTilesComponent,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessFile,
      bookingUrl,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.annieraydesign.com/"}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <DynamicTiles className={"custom-hero"}>
            <MultipleComponentIterator
              iterator={dynamicTilesComponent.childConfigurations}
              components={[
                {
                  component: <DynamicTiles.Tile />,
                  propMap: {
                    images: "files",
                    content: "contentNodes",
                    interactions: "interactions",
                    className: "data.className",
                    overlayClassName: "data.overlayClassName",
                    width: "data.width",
                    video: "data.video",
                  },
                },
              ]}
            />
          </DynamicTiles>
          <Section className={"services-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_section_header",
                defaultValue: "Discover Services",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_section_description",
              })}
            />
            <Accordion exclusive={true} className={"services-accordion"}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessMenuCategory.nodes}
                limit={4}
                components={[
                  {
                    component: <React.Fragment />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <Accordion.Title
                            activeIcon={"minus"}
                            inactiveIcon={"plus"}
                          />
                        ),
                        propMap: { index: "__itemIndex__" },
                        children: [
                          {
                            component: <Header as={"h2"} />,
                            children: [
                              {
                                component: <span />,
                                propMap: { children: "name" },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        component: <Accordion.Content />,
                        propMap: { index: "__itemIndex__" },
                        children: [
                          {
                            component: (
                              <Grid
                                className={"main content"}
                                doubling={true}
                                stackable={true}
                                columns={2}
                              />
                            ),
                            children: [
                              {
                                component: (
                                  <Grid.Column
                                    className={"services"}
                                    width={8}
                                  />
                                ),
                                children: [
                                  {
                                    component: <Grid />,
                                    children: [
                                      {
                                        component: (
                                          <MultipleComponentIteratorMap
                                            lookupField={"_id"}
                                            limit={4}
                                            iterator={
                                              allFishermanBusinessMenuItem.nodes
                                            }
                                            components={[
                                              {
                                                component: (
                                                  <Grid.Column width={16} />
                                                ),
                                                children: [
                                                  {
                                                    component: (
                                                      <InternalLink
                                                        event={{
                                                          category: "Services",
                                                          action:
                                                            "View Item Details",
                                                        }}
                                                      />
                                                    ),
                                                    propMap: {
                                                      "event.label": "name",
                                                      to: withArgs({
                                                        func: createServiceItemSlug,
                                                        args: [
                                                          allFishermanBusinessMenuCategory.nodes,
                                                        ],
                                                      }),
                                                    },
                                                    children: [
                                                      {
                                                        component: (
                                                          <Header as={"h3"} />
                                                        ),
                                                        propMap: {
                                                          children: "name",
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            ]}
                                          />
                                        ),
                                        propMap: { targetValues: "items" },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                component: (
                                  <Grid.Column
                                    className={"category-images"}
                                    width={8}
                                  />
                                ),
                                children: [
                                  {
                                    component: (
                                      <BasicCarousel
                                        size={1}
                                        displayIndicators={false}
                                        displayPlayPauseButton={false}
                                        autoScroll={false}
                                        leftArrowIcon={"fancy-arrow-left"}
                                        rightArrowIcon={"fancy-arrow-right"}
                                        animation={{
                                          name: "blur",
                                          duration: 200,
                                          transitionProps: {
                                            preAnimating: {
                                              opacity: 0.99,
                                              blur: "2px",
                                            },
                                            animating: {
                                              opacity: 0.99,
                                              blur: "2px",
                                            },
                                          },
                                        }}
                                      />
                                    ),
                                    propMap: {
                                      items: withNamedArgs({
                                        func: componentIterator,
                                        args: {
                                          iterator: withNamedArgs({
                                            func: getBusinessFiles,
                                            args: {
                                              businessFiles:
                                                allFishermanBusinessFile.nodes,
                                            },
                                          }),
                                          component: (
                                            <Image background={true} />
                                          ),
                                          propMap: { src: "__all__" },
                                        },
                                      }),
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            component: <div className={"button-container"} />,
                            children: [
                              {
                                component: (
                                  <Button
                                    basic={true}
                                    event={{
                                      category: "Services",
                                      action: "View Category Details",
                                    }}
                                    as={InternalLink}
                                    icon={
                                      <FishermanIcon
                                        iconName={"fancy-arrow-right"}
                                      />
                                    }
                                    content={"See All"}
                                    labelPosition={"right"}
                                  />
                                ),
                                propMap: {
                                  "event.label": "name",
                                  to: createServiceCategorySlug,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Accordion>
            <div className={"see-all-offering-button-container"}>
              <Button
                basic={true}
                icon={true}
                event={{ category: "Services", action: "View Services" }}
                as={InternalLink}
                to={"/services/"}
              >
                <span>See Full Service Offering</span>
                <FishermanIcon iconName={"fancy-arrow-right"} />
              </Button>
            </div>
          </Section>
          <Section className={"about-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={6} className={"markdown-column"}>
                <div>
                  <FadeReveal duration={1250} triggerOnce={true}>
                    <Header
                      as={"h2"}
                      className={"section-header"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_header",
                        defaultValue: "Our Story",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_description",
                      })}
                    />
                    <div className={"ctas-container"}>
                      <Button
                        secondary={true}
                        as={OutboundLink}
                        to={bookingUrl.url}
                        event={{
                          category: "Booking",
                          action: "Schedule Appointment Intent",
                        }}
                      >
                        {bookingUrl.title}
                      </Button>
                    </div>
                  </FadeReveal>
                </div>
              </Grid.Column>
              <Grid.Column width={10} className={"images-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <BasicCarousel
                    size={1}
                    displayIndicators={false}
                    displayPlayPauseButton={false}
                    autoScroll={false}
                    leftArrowIcon={"fancy-arrow-left"}
                    rightArrowIcon={"fancy-arrow-right"}
                    slide={true}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "ImageCollection",
                        componentIdentifier: "about_section_image_gallery",
                      }),
                      component: <Image background={true} />,
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"stylists-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_header",
                defaultValue: "Featured Team Members",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_description",
              })}
            />
            <Card.Group className={"stylists"} itemsPerRow={3}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessTeam.nodes}
                limit={3}
                components={[
                  {
                    component: (
                      <SlideReveal
                        className={"ui card"}
                        fade={true}
                        direction={"up"}
                        triggerOnce={true}
                      />
                    ),
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <BackgroundImageTeamMember
                            useModal={false}
                            as={InternalLink}
                            moreInfoIconName={"fancy-arrow-right"}
                          />
                        ),
                        propMap: {
                          "teamMember.name": "name",
                          "teamMember.role": "role",
                          "teamMember.photo": "gatsbyImage",
                          "teamMember.description": "description",
                          "teamMember.email": "email",
                          "teamMember.phone": "phone",
                          "teamMember.locations": "locations",
                          "teamMember.hours": "hours",
                          "teamMember.social": "socialMedia",
                          to: createTeamMemberSlug,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
            <div className={"see-all-stylists-button-container"}>
              <Button
                basic={true}
                icon={true}
                event={{ category: "Team", action: "View All Team Members" }}
                as={InternalLink}
                to={"/team/"}
              >
                <span>See All Team Members</span>
                <FishermanIcon iconName={"fancy-arrow-right"} />
              </Button>
            </div>
          </Section>
          <Section className={"image-gallery-section"}>
            <PaginatedGrid
              className={"image-gallery tight-overlay"}
              rows={3}
              columns={4}
              tablet={{ columns: 3, rows: 3 }}
              mobile={{ columns: 2, rows: 2, stackable: false }}
              items={componentIterator({
                iterator: getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "ImageCollection",
                  componentIdentifier: "image_gallery",
                }),
                component: <Image background={true} />,
                propMap: { src: "__all__" },
              })}
            />
            <div className={"absolute-position-overlay"}>
              <Header
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "image_section_header",
                  defaultValue: "Connect With Us",
                })}
              />
              <SocialMediaV2>
                <MultipleComponentIterator
                  components={[
                    {
                      component: <SocialMediaV2.Platform />,
                      propMap: { key: "_id", url: "link", platform: "type" },
                      children: [
                        {
                          component: <SocialMediaV2.Icon />,
                          propMap: { icon: "type" },
                        },
                      ],
                    },
                  ]}
                />
              </SocialMediaV2>
            </div>
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    dynamicTilesComponent: fishermanWebsiteComponent(
      page: { pageType: { eq: "Home" } }
      fastId: { eq: "DynamicTiles" }
    ) {
      childConfigurations {
        data {
          className
          overlayClassName
          width
          video {
            videoId
            url
            source
            placeholder
          }
        }
        files {
          _id
          altText
          file
          order
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          _id
          content
          contentType
          contentName
          order
        }
        interactions {
          _id
          displayType
          behaviorType
          enabled
          label
          order
          style
          url
        }
      }
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        _id
        description
        name
        items
        files
      }
    }
    allFishermanBusinessMenuItem(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        _id
        name
        description
        image
        visible
        available
        files
        variations {
          price
        }
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
